import React from 'react';
import {Accordion as ChakraAccordion, AccordionProps} from '@chakra-ui/react';

export type TAccordionProps = AccordionProps;

export const AccordionConfig = {
  parts: ['accordion', 'item', 'button', 'panel'],
  variants: {
    default: {
      item: {
        background: 'neutral.0',
        border: '1px',
        borderColor: 'neutral.200',
        color: 'neutral.80',
        _hover: {
          background: 'primary.25',
          borderColor: 'primary.500',
          color: 'neutral.900',
          cursor: 'pointer',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            opacity: 1,
          },
        },
        _active: {
          borderColor: 'primary.500',
          color: 'neutral.800',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            boxShadow: 'none',
          },
        },
      },
      button: {
        background: 'neutral.0',
        border: '1px',
        borderColor: 'neutral.200',
        color: 'neutral.80',
        _hover: {
          background: 'neutral.0',
          borderColor: 'primary.500',
          color: 'neutral.900',
          cursor: 'pointer',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            opacity: 1,
          },
        },
        _active: {
          borderColor: 'primary.500',
          color: 'neutral.800',
          boxShadow: 'none',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            boxShadow: 'none',
          },
        },
        _focus: {
          borderColor: 'primary.500',
          color: 'neutral.800',
          boxShadow: 'none',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            boxShadow: 'none',
          },
        },
      },
      panel: {
        border: '1px solid',
        borderTop: 'none',
        borderColor: 'neutral.200',
      },
    },
  },
  baseStyle: {},
  sizes: {},
  defaultProps: {variant: 'default'},
};
export const Accordion = (props: TAccordionProps) => <ChakraAccordion {...props} />;
