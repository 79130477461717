import {Suspense, ChangeEvent} from 'react';
import * as React from 'react';

import {Link, RouteComponentProps} from 'react-router-dom';
import {utils, topics, team} from 'spekit-datalayer';
import {
  Fontawesome,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  notifications,
  Switch,
  DSButton as Button,
  DSModal as Modal,
  DSTooltip as Tooltip,
  Icon,
  Flex,
  Box,
  Text,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'spekit-ui';
import {faInfoCircle, faLock} from '@fortawesome/free-solid-svg-icons';

import {PropagateLoader} from 'react-spinners';

import {
  AccessAllTopics,
  TeamsListResponseType,
} from 'spekit-datalayer/src/team/team.types';
import {
  Topic,
  TopicCreateResponse,
  TopicEditResponse,
  TopicDeleteResponse,
} from 'spekit-datalayer/src/topics/topics.types';
import {IFlagState} from 'spekit-types';
import {MdInfoOutline} from 'react-icons/md';
import StateManager from 'react-select';
import {track} from 'spekit-datalayer/src/analytics';
import {hasSomeOf} from '../../../../utils/permissions';
import {TopicUpload} from 'spekit-shared-components';
import {LockIcon} from '../../../../teamsListPage/teamsListTable/teamListTable.styles';
import {styles} from './styles';
import {TeamPicker} from './TeamPicker';

const {notify} = notifications;

type TTeam = {value: string; label: string; access_all_topics?: boolean};

interface TFProps extends Omit<RouteComponentProps, 'match'> {
  topic: Topic | null;
  toggle: boolean;
  flag: IFlagState;
  closeTopicModal: () => void;
  addIndexingTask: (taskId: string, taskName: string) => void;
  mode: 'edit' | 'create';
}

const DomainInput = React.lazy(() => import('../../../../components/domainInput'));

const getFormattedImage = (image: any) => {
  if (image && image.view) {
    return image.view.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gm
    )[0];
  }
  return image;
};

function makeTeamSettings(isTopicLocked: boolean, teams: TTeam[], allTeams: boolean) {
  const canUpdateAllTeams = hasSomeOf(['teams.update_all']);
  const mappedTeams = allTeams ? [] : teams.map((t) => t.value);
  if (canUpdateAllTeams)
    return {
      teams: mappedTeams,
      all_teams: allTeams,
    };
  if (isTopicLocked) return {};
  return {teams: mappedTeams};
}

class TopicForm extends React.Component<TFProps> {
  state = {
    textAreaContent: '',
    fontawesome: null,
    image: null,
    name: '',
    domains: [
      {
        name: '',
        include_subdomain: false,
        include_path: {
          enable: false,
          value: [],
        },
      },
    ] as [
      {
        name: string;
        include_subdomain: boolean;
        include_path?: {enable: boolean; value: Array<string>};
      }
    ],
    busy: false,
    deleteModal: false,
    deleteBusy: false,
    teams: [] as TTeam[],
    allTeams: false,
    teamsWithAllTopicsAccess: [] as AccessAllTopics[],
    canEdit: null,
    uiModeState: null,
    topicNameError: {required: false, matches: false},
    isProgressionEnabled: false,
  };

  whitebox = React.createRef<HTMLDivElement>();

  async componentDidMount() {
    this.getTeamsWithAllTopicsAccess();
    if (this.props.topic)
      // in edit mode
      this.setState({
        domains:
          this.props.topic.domains.length > 0
            ? this.props.topic.domains.map((domain) => ({
                name: domain.name,
                include_subdomain: domain.include_subdomain,
                include_path: {
                  enable: domain.paths?.length > 0 ? true : false,
                  value: domain.paths,
                },
              }))
            : [
                {
                  name: '',
                  include_subdomain: false,
                  include_path: {enable: false, value: []},
                },
              ],
        name: this.props.topic.name,
        textAreaContent: this.props.topic.description,
        fontawesome: this.props.topic.font_awesome_id,
        image: this.props.topic.icon,
        canEdit: this.props.topic.can_edit,
        teams: this.props.topic.teams.map((t: any) => ({
          value: t.id,
          label: t.name,
          isFixed: !t.can_user_manage,
          access_all_topics: t.access_all_topics,
        })),
        isProgressionEnabled: this.props.topic.progression,
        allTeams: this.props.topic.all_teams,
      });
  }

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({textAreaContent: e.target.value});
  };

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (text.length < 255) {
      this.setState({name: text});
    }

    // check for errors
    const regEx = /^[^,&?]+$/;
    if (text === '') {
      this.setState({
        topicNameError: {required: true, matches: false},
      });
    } else if (!text.match(regEx)) {
      this.setState({
        topicNameError: {required: false, matches: true},
      });
    } else {
      this.setState({topicNameError: {required: false, matches: false}});
    }
  };
  validateUrl = (name: string) => {
    try {
      let url = new URL(name);
      return url.host;
    } catch (e) {
      return false;
    }
  };
  handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    let domains = [...this.state.domains];
    domains[index] = {
      name: value,
      include_subdomain: domains[index].include_subdomain,
    };
    this.setState({domains});
  };

  handleSubdomainToggle = (index: number) => {
    let domains = [...this.state.domains];
    domains[index] = {
      name: domains[index].name,
      include_subdomain: !domains[index].include_subdomain,
      include_path: domains[index].include_path,
    };
    this.setState({domains});
  };
  handleDomainRemove = (domainIndex: number) => {
    let domains = [...this.state.domains];
    const filteredDomains = domains.filter((item, index) => index !== domainIndex);
    this.setState({domains: filteredDomains});
  };

  handlePathToggle = (index: number) => {
    let domains = [...this.state.domains];

    try {
      const domain = new URL(this.state.domains[index].name).origin;

      const {pathname} = new URL(this.state.domains[index].name);

      domains[index] = {
        ...this.state.domains[index],
        name: domain,
        include_path: {
          enable: !domains[index].include_path?.enable,
          value: pathname === '/' ? [''] : [pathname],
        },
      };
      this.setState({domains});
    } catch (e) {
      notify({
        error: true,
        text: 'Must be a valid URL (https://www.exampleapp.com)',
      });
    }
  };

  handleAddPath = (index: number) => {
    let domains = [...this.state.domains];

    domains[index].include_path?.value.push('');
    this.setState({domains});
  };

  handlePathChange = (value: string, pathIndex: number, domainIndex: number) => {
    let domains = [...this.state.domains];

    domains[domainIndex].include_path!.value[pathIndex]! = value;
    this.setState({domains});
  };

  handlePathRemove = (pathIndex: number, domainIndex: number) => {
    let domains = [...this.state.domains];
    const paths = domains[domainIndex].include_path!.value;
    const result = paths.filter((item, index) => index !== pathIndex);

    if (result.length === 0) {
      domains[domainIndex].include_path!.enable = false;
    }

    domains[domainIndex].include_path!.value = result;
    this.setState({domains});
  };

  clearSubdomainCheck = (index: number) => {
    let domains = [...this.state.domains];
    domains[index].include_subdomain = false;
    this.setState({domains});
  };
  closeModal = () => {
    this.props.history.push({
      ...this.props.location,
      pathname: this.props.location.pathname.replace('/create', ''),
    });
    this.props.closeTopicModal();
  };
  getIcon = (icon: {image: string; fontawesome: string}) => {
    this.setState({image: icon.image, fontawesome: icon.fontawesome});
  };
  addDomain = () => {
    let domains = [...this.state.domains];
    domains.push({name: '', include_subdomain: false});
    this.setState({domains});
  };
  openDeleteModal = () => {
    this.setState({deleteModal: true});
  };
  deleteTopic = () => {
    if (!this.props.topic) return;
    const id = this.props.topic.id;
    this.setState({deleteBusy: true});
    topics
      .deleteTopic(id)
      .then((res: TopicDeleteResponse) => {
        if (res.task_id) {
          notify({
            text: "We're deleting your topic. This may take a few minutes.",
          });
          this.props.addIndexingTask(res.task_id, this.state.name);
        } else {
          throw new Error('An error occurred, Please try again later.');
        }
        this.afterTopicDelete();
      })
      .catch((err: any) => {
        notify({
          error: true,
          text: 'An error occurred, Please try again later.',
        });
        this.setState({deleteModal: false});
      });
  };

  transformDomain = (
    domains: {
      name: string;
      include_subdomain: boolean;
      include_path?: {enable: boolean; value: Array<string>};
    }[]
  ): {
    name: string;
    include_subdomain: boolean;
    paths: {path: string; rule: string}[];
  }[] => {
    return domains
      .filter((d) => d.name !== '')
      .map((item) => ({
        name: item.name,
        include_subdomain: item.include_subdomain,
        paths: item.include_path?.enable
          ? item.include_path.value.map((path: string) => ({
              path,
              rule: 'contains',
            }))
          : [],
      }));
  };

  createTopic = () => {
    if (
      !this.isValid() ||
      this.state.topicNameError.matches ||
      this.state.topicNameError.required
    ) {
      return;
    } else if (this.checkForValidDomains() && this.validPath()) {
      let body = {
        name: this.state.name,
        description: this.state.textAreaContent,
        font_awesome_id: this.state.fontawesome,
        icon: this.state.image,
        domains: this.transformDomain(this.state.domains),
        teams: this.state.teams
          .map((t) => t.value)
          .filter((team) => team !== 'All Teams'),
        all_teams: this.state.allTeams,
      };
      topics
        .createTopic(body)
        .then((result: TopicCreateResponse) => {
          notify({text: 'Topic created.'});
          track('Topic Created', {
            screen_name: 'Topic form',
            topic_name: this.state.name,
            description: this.state.textAreaContent,
            domains: this.transformDomain(this.state.domains),
            teams: this.state.teams,
            all_teams: this.state.allTeams,
          });
          this.setState(
            {
              textAreaContent: '',
              fontawesome: null,
              image: null,
              name: '',
              domains: [{name: '', include_subdomain: false}],
              teams: [],
              all_teams: false,
              uiModeState: {
                uiMode: 'knowledge_base',
              },
            },
            this.closeModal
          );
          this.props.history.push(
            `/app/wiki/?type=object,field_value,business_term,asset&topic=${
              result.id
            }&tag=${encodeURIComponent(result.name)}`
          );
        })
        .catch((err: any) => {
          notify({error: true, text: err.message});
          this.closeModal();
        });
    }
  };
  editTopic = () => {
    if (!this.isValid() || !this.props.topic) {
      return;
    }
    const id = this.props.topic.id;
    if (this.checkForValidDomains() && this.validPath()) {
      this.setState({busy: true});

      let body = {
        name: this.state.name,
        description: this.state.textAreaContent,
        font_awesome_id: this.state.fontawesome,
        icon: getFormattedImage(this.state.image),
        domains: this.transformDomain(this.state.domains),
        ...(this.props.topic.progression !== this.state.isProgressionEnabled && {
          progression: this.state.isProgressionEnabled,
        }),
        ...makeTeamSettings(
          this.props.topic.all_teams,
          this.state.teams,
          this.state.allTeams
        ),
      };
      topics
        .editTopic(id, body)
        .then((res: TopicEditResponse) => {
          if (res.task_id) {
            notify({
              text: "We're updating your topic. This may take a few minutes.",
            });
            this.props.addIndexingTask(res.task_id, this.state.name);
          } else {
            notify({text: this.state.name + ' updated'});
          }
          this.setState({busy: false});
          this.afterTopicUpdate();
        })
        .catch((err: any) => {
          notify({
            error: true,
            text: err.message,
          });
          this.setState({busy: false});
          this.closeModal();
        });
    }
  };

  progressionToggle = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({isProgressionEnabled: event.target.checked});
  };

  forceRefresh = (route: string, search?: string) => {
    this.props.history.push('/empty');
    this.props.history.replace({pathname: route, search});
  };

  afterTopicUpdate = () => {
    track('Topic Edited', {
      screen_name: 'Topic form',
      topic_name: this.state.name,
      description: this.state.textAreaContent,
      domains: this.transformDomain(this.state.domains),
      teams: this.state.teams,
      all_teams: this.state.allTeams,
    });

    this.closeModal();

    const filters = utils.parseQs(this.props.location.search);
    if (this.props.location.pathname === '/app/wiki/') {
      if (filters.tag) {
        filters.tag = encodeURIComponent(this.state.name);
      }

      this.props.history.push({
        pathname: `${this.props.location.pathname}`,
        state: {},
        search: utils.stringifyQs(filters),
      });
    } else {
      this.forceRefresh('/app/wiki/topics', utils.stringifyQs(filters));
    }
  };
  afterTopicDelete = () => {
    this.setState({
      deleteBusy: false,
    });
    track('Topic Deleted', {
      screen_name: 'Topic form',
      topic_name: this.state.name,
    });
    this.setState({deleteModal: false});
    this.forceRefresh('/app/wiki/topics');
    this.props.closeTopicModal();
  };
  getTeamsWithAllTopicsAccess = () => {
    team.getTeamsWithAllTopicsAccess().then((result: TeamsListResponseType) => {
      this.setState({teamsWithAllTopicsAccess: result.results});
    });
  };

  checkForValidDomains = () => {
    const isValid = this.state.domains.every(
      (domain) => this.validateUrl(domain.name) || domain.name === ''
    );

    if (!isValid) {
      notify({
        error: true,
        text: 'Domain URL is invalid',
      });
      return isValid;
    }

    const isDuplicate = this.state.domains.some((domain, index) =>
      this.state.domains.some((_domain, _index) => {
        if (domain.name.length && _domain.name.length) {
          return (
            new URL(domain.name).hostname === new URL(_domain.name).hostname &&
            index !== _index
          );
        }
        return false;
      })
    );

    if (isDuplicate) {
      notify({
        error: true,
        text: `Topic URL can't be Duplicate`,
      });
    }

    return !isDuplicate;
  };

  validPath = () => {
    let isValid = true;
    isValid = this.state.domains
      .filter((domain) => domain.include_path?.enable)
      .every((domainWithPath) =>
        domainWithPath.include_path?.value.every((path) => {
          if (path === '') {
            notify({
              error: true,
              text: 'Path input field cannot be blank',
            });

            return false;
          }
          if (path === '/' || !path.startsWith('/') || path.includes(' ')) {
            notify({
              error: true,
              text: 'Invalid path',
            });
            return false;
          }

          return true;
        })
      );

    return isValid;
  };

  isValid = () => {
    if (!this.state.name) {
      this.setState({topicNameError: {...this.state.topicNameError, required: true}});
      return false;
    } else if (
      !this.state.teams.length &&
      !this.state.teamsWithAllTopicsAccess.length &&
      !this.state.allTeams
    ) {
      notify({
        text: 'Please select at least one team that should have access to this topic. ',
        error: true,
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {mode} = this.props;
    const canUpdateAllTeams = hasSomeOf(['teams.update_all']);
    const isLockedTopic =
      this.props.topic && this.props.topic.all_teams && !canUpdateAllTeams;

    let teamsWithAllTopicsAccess = this.state.teamsWithAllTopicsAccess.map((value) => {
      if (
        hasSomeOf(['teams.add_own', 'teams.update_own', 'teams.delete_own']) &&
        !value.system
      ) {
        let to = {
          pathname: `/app/settings/access/teams/${value.id}`,
          state: value,
        };
        return (
          <div key={value.id}>
            <Link
              style={styles.teamsLink}
              onClick={(e) => {
                e.stopPropagation();
                this.closeModal();
              }}
              to={to}
            >
              {value.name}
            </Link>
          </div>
        );
      } else {
        return (
          <div key={value.id}>
            <label style={styles.teamsdisableLink}>{value.name}</label>
            <LockIcon name={faLock} />
          </div>
        );
      }
    });

    const isTopicNameInvalid = Object.values(this.state.topicNameError).some(
      (error) => error
    );

    return (
      <Suspense fallback={<PropagateLoader color='#3576E8' loading={true} />}>
        <div>
          <StateManager stateUpdate={this.state.uiModeState} />
          <Modal
            size='lg'
            scrollBehavior='outside'
            onClose={this.closeModal}
            isOpen={this.props.toggle}
            containerProps={{alignItems: 'baseline'}}
            closeOnEsc={false}
          >
            <ModalCloseButton />
            <ModalHeader id='Topic Modal'>
              {this.props.topic !== null
                ? `Editing ”${this.props.topic.name}"`
                : 'Create a new Topic'}
            </ModalHeader>

            <ModalBody>
              <FormControl isRequired isInvalid={isTopicNameInvalid}>
                <Flex gap='3px' mb={6}>
                  <FormLabel m={0}>Topic name</FormLabel>
                  <Tooltip label='Special characters like ampersand (&), question mark (?) and comma (,) are not supported.'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <Icon
                        color='neutral.800'
                        h='16px'
                        w='16px'
                        as={MdInfoOutline}
                        data-testid='topic-name-tooltip'
                      />
                    </Box>
                  </Tooltip>
                </Flex>
                <Input
                  isRequired
                  placeholder='Enter Topic name'
                  data-testid='topic-name'
                  value={this.state.name}
                  isInvalid={isTopicNameInvalid}
                  onChange={this.handleNameChange}
                />
                <FormErrorMessage>
                  {this.state.topicNameError.required && 'Enter Topic name'}
                  {this.state.topicNameError.matches &&
                    'Special characters like ampersand (&), question mark (?) and comma (,) are not supported.'}
                </FormErrorMessage>
              </FormControl>

              <div style={styles.content}>
                <div style={styles.descriptionContainer}>
                  <div style={styles.description}>Description</div>
                  <textarea
                    data-testid='topic-description'
                    placeholder={`Add a description that clearly outlines the type of content (what it is), the audience (who it's for) and where they should be using it. Max 255 characters.`}
                    cols={40}
                    rows={4}
                    style={styles.textArea}
                    value={this.state.textAreaContent}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div style={styles.iconContainer}>
                  <Flex gap={2} mb={20}>
                    <Text variant='body2' fontWeight='semibold' color='neutral.800'>
                      Icon
                    </Text>
                    <Tooltip label='If uploading your own, we recommend using an icon that is 126 x 126px'>
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <Icon
                          color='neutral.800'
                          as={MdInfoOutline}
                          data-testid='topic-icon-tooltip'
                        />
                      </Box>
                    </Tooltip>
                  </Flex>

                  <TopicUpload
                    getIcon={this.getIcon}
                    image={this.state.image}
                    fontawesome={this.state.fontawesome}
                  />
                </div>
              </div>
              <div style={styles.domainsContainer}>
                <div>
                  <span style={styles.domainLabel}>Related domains</span>
                  <Tooltip
                    label="Display the most relevant topics on any site. When your team opens the Spekit Chrome sidebar on a related domain, they'll see only topics relevant to that domain."
                    shouldWrapChildren
                  >
                    <Fontawesome
                      name={faInfoCircle}
                      style={styles.info}
                      data-testid='related-domains-tooltip'
                    />
                  </Tooltip>
                </div>
                <div style={styles.domainSub}>
                  Surface relevant topics for your team through Spekit for Chrome on
                  specific applications or websites.
                </div>
                <div>
                  {this.state.domains.map((domain, index, array) => {
                    if (index === array.length - 1) {
                      return (
                        <DomainInput
                          name={domain.name}
                          includeSubdomain={domain.include_subdomain}
                          includePath={domain.include_path}
                          handleChange={this.handleDomainChange}
                          lastItem
                          addDomain={this.addDomain}
                          key={index}
                          index={index}
                          domains={array}
                          showErrorMessage
                          onSubdomainToggle={this.handleSubdomainToggle}
                          onDomainRemove={this.handleDomainRemove}
                          onPathToggle={this.handlePathToggle}
                          onAddPath={this.handleAddPath}
                          onPathRemove={this.handlePathRemove}
                          onPathChange={this.handlePathChange}
                          onClearSubdomainCheck={this.clearSubdomainCheck}
                        />
                      );
                    }
                    return (
                      <DomainInput
                        name={domain.name}
                        includeSubdomain={domain.include_subdomain}
                        includePath={domain.include_path}
                        handleChange={this.handleDomainChange}
                        index={index}
                        key={index}
                        domains={array}
                        showErrorMessage
                        onSubdomainToggle={this.handleSubdomainToggle}
                        onDomainRemove={this.handleDomainRemove}
                        onPathToggle={this.handlePathToggle}
                        onAddPath={this.handleAddPath}
                        onPathRemove={this.handlePathRemove}
                        onPathChange={this.handlePathChange}
                        onClearSubdomainCheck={this.clearSubdomainCheck}
                      />
                    );
                  })}
                </div>
              </div>
              {!isLockedTopic && (
                <div style={styles.teamsContainer}>
                  <div>
                    <span style={styles.domainLabel}>
                      Teams that can access this topic
                    </span>
                    <Tooltip
                      label='Only teams selected will be able to see Spekit help icons or search results from this Topic.'
                      shouldWrapChildren
                    >
                      <Fontawesome
                        name={faInfoCircle}
                        style={styles.info}
                        data-testid='teams-tooltip'
                      />
                    </Tooltip>
                  </div>
                  <div style={styles.domainSub}>
                    Choose which teams should access this topic.
                  </div>
                  <TeamPicker
                    allTeams={this.state.allTeams}
                    selectedTeams={this.state.teams}
                    setAllTeams={(allTeams) => this.setState({allTeams})}
                    setSelectedTeams={(teams) => this.setState({teams})}
                  />
                  {canUpdateAllTeams && (
                    <div style={styles.teamsContainer}>
                      <div>
                        <span style={styles.domainLabel}>
                          Teams that have permission to access ALL topics
                        </span>
                        <Tooltip
                          label='These are teams that already, based on their settings, have access to All Topics, including this one.'
                          shouldWrapChildren
                        >
                          <Fontawesome
                            name={faInfoCircle}
                            style={styles.info}
                            data-testid='all-topics-team-tooltip'
                          />
                        </Tooltip>
                      </div>
                      <div style={styles.domainSub}>
                        Only admins can edit this setting from the{' '}
                        <Link to='/app/settings/access/teams/' onClick={this.closeModal}>
                          Teams page
                        </Link>
                      </div>
                    </div>
                  )}
                  <div style={styles.teamsContainer}>{teamsWithAllTopicsAccess}</div>
                </div>
              )}
              {this.props.topic !== null && this.props.flag.hasProgressionFlag && (
                <Flex direction='column' alignItems='flex-start' gap={6}>
                  <div style={styles.trackCompletionLabel}>
                    <span>Progress tracking for viewers</span>
                    <Switch
                      data-testid={'progression-toggle'}
                      style={styles.trackCompletionSwitch}
                      onChange={this.progressionToggle}
                      isChecked={this.state.isProgressionEnabled}
                    ></Switch>
                  </div>
                  <div style={styles.trackCompletionSubLabel}>
                    <span>
                      Enable viewers to visually track their progress on this topic based
                      on the % of speks they have read.
                    </span>
                  </div>
                </Flex>
              )}
            </ModalBody>

            <ModalFooter justifyContent={mode === 'edit' ? 'space-between' : 'center'}>
              {mode === 'edit' ? (
                <>
                  <Box>
                    {this.state.canEdit && (
                      <Button
                        data-testid='confirm-delete-btn'
                        size='medium'
                        variant='danger'
                        onClick={this.openDeleteModal}
                      >
                        Delete Topic
                      </Button>
                    )}
                  </Box>

                  <Flex gap={6}>
                    <Button
                      onClick={this.closeModal}
                      colorScheme='white'
                      size='medium'
                      variant='ghost'
                      data-testid='edit-topic-cancel-btn'
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testid='save-topic-btn'
                      size='medium'
                      variant='contained'
                      colorScheme='primary'
                      isLoading={this.state.busy}
                      onClick={() => this.editTopic()}
                    >
                      Save Topic
                    </Button>
                  </Flex>
                </>
              ) : (
                <Button
                  data-testid='create-topic'
                  size='medium'
                  variant='contained'
                  colorScheme='primary'
                  onClick={this.createTopic}
                >
                  Create Topic
                </Button>
              )}
            </ModalFooter>
          </Modal>

          {/* delete topic modal */}
          {this.props.topic && (
            <Modal
              size='sm'
              isOpen={this.state.deleteModal}
              onClose={() => {
                this.setState({deleteModal: false});
              }}
            >
              <ModalCloseButton />
              <ModalHeader>Are you sure you want to delete this Topic?</ModalHeader>

              <ModalBody>
                Users in your org will no longer have access to this Topic and all Speks
                within it will be removed.
              </ModalBody>

              <ModalFooter>
                <Flex gap={6}>
                  <Button
                    onClick={() => {
                      this.setState({deleteModal: false});
                    }}
                    colorScheme='white'
                    size='medium'
                    variant='ghost'
                    data-testid='delete-topic-cancel-btn'
                  >
                    No, return to editing
                  </Button>
                  <Button
                    data-testid='delete-btn'
                    size='medium'
                    variant='danger'
                    onClick={() => this.deleteTopic()}
                    isLoading={this.state.deleteBusy}
                  >
                    Yes, delete
                  </Button>
                </Flex>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </Suspense>
    );
  }
}

export default TopicForm;
